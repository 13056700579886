<template>
    <v-button
        variant="icon"
        class="theme-switcher"
        @click="changeTheme"
    >
        <icon
            :glyph="currentGlyph"
            size="1x"
        />
    </v-button>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import VButton from './VButton.vue';
import Icon from '../Presentation/Icon.vue';

export default {
    name: 'ThemeSwitcher',
    components: { VButton, Icon },
    computed: {
        ...mapState('dashboard', ['theme']),
        currentGlyph() {
            return this.theme === 'light'
                ? 'dark-mode'
                : 'light-mode';
        },
    },
    methods: {
        ...mapActions('dashboard', ['setTheme']),
        changeTheme() {
            const theme = this.theme === 'light'
                ? 'dark'
                : 'light';

            this.setTheme({ theme });
        },
    },
};
</script>

<style lang="scss">
.theme-switcher {
    color: red;
}
</style>
