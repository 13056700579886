<template>
   <div class="sidebar">
       <header class="sidebar__header">
           <logo class="sidebar__logo" />
       </header>

       <navigation />

       <div
           class="sidebar__action"
           :class="{ '-is-active': sidebarOpen }"
       >
           <v-button
               variant="icon"
               class="sidebar__action-button"
               :transform="false"
               @click="toggleSidebar"
           >
               <span
                   class="sidebar__action-text"
                   :class="{ '-is-visible': sidebarOpen }"
               >
                   Close Sidebar
               </span>
               <icon
                   :glyph="`keyboard-arrow-${sidebarOpen ? 'left' : 'right'}`"
                   size="2x"
               />
           </v-button>
       </div>
   </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Logo from '../../components/Presentation/Logo.vue';
import Navigation from './Navigation.vue';
import Icon from '../../components/Presentation/Icon.vue';
import VButton from '../../components/Action/VButton.vue';

export default {
    name: 'Sidebar',
    components: {
        VButton,
        Icon,
        Navigation,
        Logo,
    },
    computed: {
        ...mapState('dashboard', ['sidebarOpen']),
        ...mapState('screen', ['screen']),
    },
    methods: {
        ...mapActions('dashboard', ['toggleSidebar', 'closeSidebar']),
    },
    watch: {
        screen({ size }) {
            if (size < 4) {
                this.closeSidebar();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.sidebar {
    height: 100vh;
    position: relative;

    &__header {
        align-items: center;
        border-bottom: 1px solid var(--border-color);
        display: flex;
        height: 7rem;
        justify-content: center;
        margin-bottom: 2rem;
        padding: 2rem;
    }

    &__action-button {
        @include size(100%, 6rem);
        @include absolute($bottom: 0);

        border-top: 1px solid var(--border-color);
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__action-text {
        font-size: 1.3rem;
        opacity: 0;
        width: 0;
        transition-delay: .2s;
        transition: opacity .2s;

        &.-is-visible {
            opacity: 1;
            width: auto;
            transition-delay: 0s;
        }
    }
}
</style>
