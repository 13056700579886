<template>
    <div
        class="dashboard"
        :class="{ '-sidebar-active': sidebarOpen }"
    >
        <page-loading-indicator />

        <div
            class="dashboard__sidebar"
            :class="{ '-is-active': sidebarOpen }"
        >
            <sidebar />
        </div>

        <main class="dashboard__main">
            <div class="container">
                <content-area class="dashboard__header">
                    <breadcrumbs />
                    <theme-switcher />
                </content-area>
            </div>

            <router-view />
        </main>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Sidebar from './Sidebar.vue';
import PageLoadingIndicator from '../../components/Presentation/PageLoadingIndicator.vue';
import ContentArea from '../../components/Presentation/ContentArea.vue';
import Breadcrumbs from './Breadcrumbs.vue';
import ThemeSwitcher from '../../components/Action/ThemeSwitcher.vue';

export default {
    name: 'Dashboard',
    components: {
        ThemeSwitcher,
        Breadcrumbs,
        ContentArea,
        PageLoadingIndicator,
        Sidebar,
    },
    computed: {
        ...mapState('dashboard', ['sidebarOpen']),
    },
    methods: {
        ...mapActions('dashboard', ['closeSidebar', 'toggleSidebar']),
    },
};
</script>

<style lang="scss" scoped>
.dashboard {
    $scope: &;
    position: relative;

    &__header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 4rem 0;
    }

    &__sidebar {
        @include fixed(0, false, 0);

        background: var(--sidebar-bg-color);
        box-shadow: var(--box-shadow);
        transition: width .2s;
        height: 100vh;
        width: 7rem;

        &.-is-active {
            width: 23rem;
        }
    }

    &__main {
        width: calc(100% - 7rem);
        transform: translateX(7rem);
        transition: transform .2s, width .2s;
    }

    &.-sidebar-active {
        #{$scope} {
            &__main {
                width: calc(100% - 23rem);
                transform: translateX(23rem);
            }
        }
    }
}
</style>
