<template>
    <i :class="classes" />
</template>

<script>
export default {
    name: 'Icon',
    props: {
        size: {
            type: String,
            default: '',
        },
        glyph: {
            type: String,
            required: true,
        },
    },
    computed: {
        classes() {
            const classes = [
                'icon',
                'mdi-round',
                `mdi-${this.glyph}`,
            ];

            if (this.size) {
                classes.push(`mdi-${this.size}`);
            }

            return classes;
        },
    },
};
</script>

<style lang="scss">
.icon {
    color: var(--icon-color);
}
</style>
