<template>
    <h1 class="logo">
        <router-link :to="{ name: 'dashboard.home' }">
            {{ logo }}
        </router-link>
    </h1>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Logo',
    props: {
        collapse: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapState('dashboard', ['sidebarOpen']),
        logo() {
            const variants = { small: 'P', normal: 'Pixtur' };

            if (!this.collapse || this.sidebarOpen) {
                return variants.normal;
            }

            return variants.small;
        },
    },
    // mounted() {
    //     if (this.collapse) {
    //         this.logo = this.sidebarOpen
    //             ? 'Pixtur'
    //             : 'P';
    //     }
    //
    //     this.logo = 'Pixtur';
    // },
    // watch: {
    //     sidebarOpen(val) {
    //         this.logo = 'Pixtur';
    //
    //         if (this.collapse && !val) {
    //             setTimeout(() => {
    //                 this.logo = 'P';
    //             }, 100);
    //         }
    //     },
    // },
    // data: () => ({
    //     logo: '',
    // }),
};
</script>

<style lang="scss">
.logo {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: var(--logo-gradient);
    cursor: default;
    font-family: $logoFont;
    line-height: 1;
    margin: 0;
    user-select: none;
}
</style>
