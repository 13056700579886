<template>
    <nav
        class="breadcrumbs"
        aria-label="Breadcrumb"
        v-if="breadcrumbs.length > 1"
    >
        <ol class="breadcrumbs__list">
            <li
                v-for="(crumb, i) in breadcrumbs"
                :key="i"
                class="breadcrumbs__list-item"
            >
                <template v-if="i !== (breadcrumbs.length - 1)">
                    <router-link :to="crumb.to">
                        {{ crumb.name }}
                    </router-link>
                    <icon
                        class="breadcrumbs__arrow"
                        glyph="arrow-forward-ios"
                    />
                </template>
                <template v-else>
                    <span class="breadcrumbs__list-item -no-link">{{ crumb.name }}</span>
                </template>
            </li>
        </ol>
    </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Icon from '../../components/Presentation/Icon.vue';

export default {
    name: 'Breadcrumbs',
    components: { Icon },
    computed: {
        ...mapState('breadcrumbs', ['breadcrumbs']),
    },
    methods: {
        ...mapActions('breadcrumbs', ['setBreadcrumbs']),
    },
    mounted() {
        this.setBreadcrumbs(this.$route.matched);
    },
    watch: {
        $route() {
            this.setBreadcrumbs(this.$route.matched);
        },
    },
};
</script>

<style lang="scss">
.breadcrumbs {
    &__list {
        display: flex;
    }

    &__list-item {
        font-size: 1.4rem;
        font-weight: $boldFontWeight;

        &.-no-link {
            cursor: default;
            color: var(--link-color);
            font-weight: $normalFontWeight;
        }
    }

    &__arrow {
        font-size: 1.2rem;
        margin: 0 .5rem;
        color: var(--link-color);
    }
}
</style>
