<template>
    <nav class="navigation">
        <ol class="navigation__list">
            <li>
                <router-link
                    v-for="({ to, name, icon }, i) in navigation"
                    class="navigation__list-item"
                    :class="{ '-sidebar-open': sidebarOpen, 'router-link-exact-active': isActive(to) }"
                    :key="i"
                    tag="li"
                    :to="to"
                >
                <span class="navigation__list-item-text">
                    {{ name }}
                </span>
                    <span class="navigation__list-item-icon">
                    <icon :glyph="icon" />
                </span>
                </router-link>
            </li>
        </ol>
    </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Icon from '../../components/Presentation/Icon.vue';

export default {
    name: 'Navigation',
    components: { Icon },
    computed: {
        ...mapState('dashboard', ['navigation', 'sidebarOpen']),
    },
    methods: {
        ...mapActions('dashboard', ['fetchNavigation']),
        isActive(to) {
            return to.name === this.$route.name.split('.').slice(0, 2).join('.');
        },
    },
    async mounted() {
        await this.fetchNavigation();
    },
};
</script>

<style lang="scss" scoped>
.navigation {
    $scope: &;

    &__list {
        display: flex;
        flex-direction: column;
    }

    &__list-item {
        position: relative;
        padding: 1rem 1.5rem;
        font-size: 1.4rem;
        color: var(--navigation-link-color);
        transition: width .2s, padding .2s, transform .2s;
        width: 100%;
        display: flex;
        justify-content: center;

        &::after {
            @include absolute($top: calc(100% - .5rem), $left: 50%);

            transform: translateX(-50%);
            content: '';
            width: 3rem;
            border-bottom: 1px solid var(--border-color);
        }

        &.-sidebar-open {
            justify-content: space-between;

            &::after {
                display: none;
            }

            #{$scope} {
                &__list-item-text {
                    opacity: 1;
                    visibility: visible;
                    width: 100%;
                    height: auto;
                    line-height: initial;
                }

                &__list-item-icon {
                    margin-right: 1.5rem;
                }
            }
        }

        &.router-link-exact-active {
            color: var(--navigation-link-active-color);
            background-image: var(--navbar-link-active-gradient);
            width: calc(100% + 1rem);
            padding-right: 1.8rem;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            box-shadow: var(--navigation-link-active-box-shadow);

            &::after {
                display: none;
            }

            #{$scope} {
                &__list-item-icon {
                    > .icon {
                        color: var(--navigation-icon-active-color);
                    }
                }
            }
        }
    }

    &__list-item-text {
        @include absolute($left: 5rem);

        opacity: 0;
        transition: opacity .2s;
        visibility: hidden;
        transition-delay: .2s;
        width: 0;
        height: 0;
        line-height: 0;
    }

    &__list-item-icon,
    &__list-item-text {
        display: block;
    }

    &__list-item-icon {
        color: var(--navigation-icon-color);
        font-size: 2rem;
    }
}
</style>
