<template>
    <div
        :style="style"
        class="progress"
    />
</template>

<script>
import { mapState } from 'vuex';

let timer;
let cut;

// https://github.com/nuxt/nuxt.js/blob/master/lib/app/components/nuxt-loading.vue
export default {
    data: () => ({
        percent: 0,
        show: false,
        canSuccess: true,
        duration: 3000,
        height: '2px',
        color: '#10b981',
        failedColor: 'red',
        timer: null,
        cut: null,
    }),
    computed: {
        ...mapState('loading', ['loading']),
        style() {
            return {
                width: `${this.percent}%`,
                height: this.height,
                opacity: this.show ? 1 : 0,
                'background-color': this.canSuccess ? this.color : this.failedColor,
            };
        },
    },
    watch: {
        loading(val) {
            if (val) {
                this.start();
            } else {
                this.finish();
            }
        },
    },
    methods: {
        start() {
            this.show = true;
            this.canSuccess = true;

            if (timer) {
                clearInterval(timer);
                this.percent = 0;
            }

            cut = 10000 / Math.floor(this.duration);

            timer = setInterval(() => {
                this.increase(cut * Math.random());

                if (this.percent > 95) {
                    this.finish();
                }
            }, 100);

            return this;
        },
        set(num) {
            this.show = true;
            this.canSuccess = true;
            this.percent = Math.floor(num);

            return this;
        },
        get() {
            return Math.floor(this.percent);
        },
        increase(num) {
            this.percent += Math.floor(num);

            return this;
        },
        decrease(num) {
            this.percent -= Math.floor(num);

            return this;
        },
        finish() {
            this.percent = 100;
            this.hide();

            return this;
        },
        pause() {
            clearInterval(timer);

            return this;
        },
        hide() {
            clearInterval(timer);

            timer = null;

            setTimeout(() => {
                this.show = false;

                this.$nextTick(() => {
                    setTimeout(() => {
                        this.percent = 0;
                    }, 200);
                });
            }, 500);

            return this;
        },
        fail() {
            this.canSuccess = false;

            return this;
        },
    },
};
</script>

<style lang="scss" scoped>
.progress {
    background-color: #efc14e;
    height: .2rem;
    left: 0;
    opacity: 1;
    position: fixed;
    right: 0;
    top: 0;
    transition: width .2s, opacity .4s;
    width: 0;
    z-index: 999999;
}
</style>
