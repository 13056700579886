<template>
    <component
        :is="to ? 'router-link' : 'button'"
        :to="to"
        tag="button"
        class="button"
        :class="classes"
    >
        <span
            class="button__text-wrap"
            :class="{ '-is-loading': loading }"
        >
            <slot />
        </span>
        <loading-spinner
            v-if="loading"
            class="button__spinner"
        />
    </component>
</template>

<script>
import LoadingSpinner from '../Presentation/LoadingSpinner.vue';

const variants = ['icon'];

export default {
    name: 'VButton',
    components: { LoadingSpinner },
    props: {
        variant: {
            type: String,
            default: '',
            validator: (val) => variants.includes(val),
        },
        to: {
            type: [Object, String],
            default: '',
        },
        theme: {
            type: String,
            default: 'default',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        transform: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        classes() {
            const classes = [
                `-${this.theme}`,
            ];

            if (this.variant) {
                classes.push(`-${this.variant}`);
            }

            if (!this.transform) {
                classes.push('-no-transform');
            }

            return classes;
        },
    },
    methods: {
        handleClick(e) {
            if (this.to) {
                this.$router.push(this.to);
            }

            this.$emit('click', e);
        },
    },
};
</script>

<style lang="scss">
a.button,
.button {
    position: relative;
    color: var(--button-font-color);
    align-items: center;
    background: var(--button-bg-color);
    display: flex;
    justify-content: center;
    border-radius: var(--border-radius);
    font-weight: $boldFontWeight;
    z-index: 2;

    &:active {
        transform: translateX(.1rem) translateY(.1rem);
        z-index: 1;
    }

    &.-no-transform {
        &:active {
            transform: none;
        }
    }

    &:hover,
    &:active {
        background-color: var(--button-hover-color);
    }

    &.-icon {
        background: transparent;
        padding: 0;
    }

    &.-info {
        background: var(--button-info-theme-bg-color);

        &:active,
        &:hover {
            background: var(--button-info-theme-bg-hover-color);
        }
    }

    &.-error {
        background: var(--button-error-theme-bg-color);

        &:active,
        &:hover {
            background: var(--button-error-theme-bg-hover-color);
        }
    }

    &.-success {
        background: var(--button-success-theme-bg-color);

        &:active,
        &:hover {
            background: var(--button-success-theme-bg-hover-color);
        }
    }

    &__text-wrap {
        display: flex;
        align-items: center;
        justify-content: center;

        &.-is-loading {
            color: transparent;
        }
    }

    &[disabled] {
        opacity: .8;
        background-color: var(--button-disabled-bg-color);

        &:active {
            transform: none;
        }

        &:hover {
            color: var(--button-font-color);
        }
    }

    &__spinner {
        @include absolute();

        height: 50%;
    }
}
</style>
